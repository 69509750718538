<template>
  <div>
    <vue-header title="面诊" isReturn="true"/>
    <camera type="1"/>
  </div>
</template>
<script>
  import vueHeader from "@/components/header";
  import camera from "@/components/camera";
  export default {
    name: "step1",
    components: {
      vueHeader,
      camera
    }
  };
</script>